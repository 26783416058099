import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const FiltersContainer = styled.div`
  margin-bottom: 30px;
  display: block;

  @media (max-width: 767px) {
    order: 3;
    flex: 0 0 100%;
    display: ${(props) => (props.isMobileFilterMenuOpen ? 'block' : 'none')};
  }
`;

const FilterButton = styled.button`
  margin-right: 22px;

  text-transform: capitalize;

  border-bottom: ${(props) =>
    props.activeFilter ? '2px solid #fa3c44' : 'none'};
`;

const MobileFilterToggle = styled.button`
  order: 1;

  display: none;
  margin-right: 22px;
  margin-bottom: 30px;

  text-transform: capitalize;

  color: ${(props) =>
    props.isMobileFilterOpen ? '#fa3c44' : 'rgba(1,59,210,1)'};

  @media (max-width: 767px) {
    display: block;
  }
`;

const FilterLink = styled.div`
  display: inline-block;
  margin-right: 22px;

  text-transform: capitalize;

  & a {
    border-bottom: ${(props) =>
      props.activeFilter ? '2px solid #fa3c44' : 'none'};
  }
`;

export const ArtistFilters = ({
  activeFilter,
  onClick,
  allFilters,
  location,
  artistUid,
}) => {
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  const singleFilters = allFilters.map((filter, index) => {
    if (filter === `Animation`) {
      return (
        <FilterLink activeFilter={activeFilter === filter ? true : false}>
          <Link
            to={`/artists/${artistUid}/animation/`}
            state={{ activeFilter: `Animation` }}
            activeClassName="current"
          >
            {filter}
          </Link>
        </FilterLink>
      );
    } else if (filter === `Illustration`) {
      return (
        <FilterLink activeFilter={activeFilter === filter ? true : false}>
          <Link
            to={`/artists/${artistUid}/`}
            state={{ activeFilter: `Illustration` }}
            activeClassName="current"
          >
            {filter}
          </Link>{' '}
        </FilterLink>
      );
    } else {
      return (
        <FilterButton
          activeFilter={activeFilter === filter ? true : false}
          key={index}
          onClick={() => {
            onClick(filter);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }}
        >
          {filter}
        </FilterButton>
      );
    }
  });

  return (
    <React.Fragment>
      <MobileFilterToggle
        isMobileFilterOpen={isMobileFilterOpen}
        onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}
      >
        + Filter
      </MobileFilterToggle>

      <FiltersContainer isMobileFilterMenuOpen={isMobileFilterOpen}>
        {singleFilters}
      </FiltersContainer>
    </React.Fragment>
  );
};
