import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: ${(props) => props.aspectRatio + `%`};
  margin-bottom: 30px;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ShowreelContainer = styled.div`
  position: relative;

  & .artist-name {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    z-index: 100;

    & h1 {
      font-size: 100px;
      line-height: 115%;

      @media (max-width: 1360px) {
        font-size: 80px;
      }

      @media (max-width: 1100px) {
        font-size: 70px;
      }

      @media (max-width: 900px) {
        font-size: 50px;
      }

      @media (max-width: 640px) {
        font-size: 30px;
      }
    }
  }
`;

const Poster = styled.div`
  & img {
    z-index: 40;

    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const TitleContainer = styled.div`
  padding: 100px 0;

  @media (max-width: 767px) {
    padding: 50px 0;
  }

  @media (max-width: 500px) {
    padding: 10px 0 50px 0;
  }
`;

async function getVimeoThumbnail(video, width, height) {
  let response = await fetch(
    `https://vimeo.com/api/oembed.json?url=${video}&width=${width}&height=${height}&quality=1080`
  );

  let data = await response.json();
  return data;
}

export const ArtistShowreel = ({ name, showreel }) => {
  const [isPosterVisible, setPosterVisible] = useState(true);
  const [thumbnail, setThumbnail] = useState(null);

  let vimeoThumbnail =
    showreel.embed_url !== null
      ? getVimeoThumbnail(showreel.embed_url, 1920, 1080)
      : null;

  useEffect(() => {
    if (vimeoThumbnail !== null) {
      vimeoThumbnail.then((result) => {
        setThumbnail(result.thumbnail_url);
      });
    }
  }, [vimeoThumbnail]);

  if (showreel.embed_url !== null) {
    return (
      <ShowreelContainer>
        <div
          className="artist-name"
          dangerouslySetInnerHTML={{
            __html: name.html,
          }}
        />

        <IframeContainer aspectRatio={(showreel.height / showreel.width) * 100}>
          {isPosterVisible && thumbnail !== null && (
            <Poster>
              <img src={thumbnail} alt="Thumbnail" />
            </Poster>
          )}
          <ReactPlayer
            url={showreel.embed_url}
            width="100%"
            height="100%"
            playing={true}
            controls
            loop={true}
            playsinline={true}
            autoPlay={true}
            volume={0}
            muted={true}
            onStart={() => {
              setPosterVisible(false);
            }}
            config={{
              vimeo: {
                playerOptions: {
                  background: 1,
                  loop: 1,
                  autoPlay: 1,
                  muted: 1,
                },
              },
            }}
          />
        </IframeContainer>
      </ShowreelContainer>
    );
  } else {
    return (
      <TitleContainer>
        <div
          className="artist-name"
          dangerouslySetInnerHTML={{
            __html: name.html,
          }}
        />
      </TitleContainer>
    );
  }
};
