import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import '../Styles/carousel.css';

const AnimationContainerLandscape = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 100px);

  & video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: calc(100vh - 100px);

    padding: 0 100px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }

  & iframe {
    z-index: 1;
  }
`;

const AnimationContainerPortrait = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 100px);

  & video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: calc(100vh - 100px);

    padding: 0 100px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }
`;

const IllustrationContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 100px);

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: calc(100vh - 100px);

    padding: 0 100px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }
`;

const Gallery = ({ projects, activeSlide }) => {
  const allProjects = projects.map((project, index) => {
    if (project.slice_type === 'illustration') {
      if (
        project.primary.image.dimensions.width >
        project.primary.image.dimensions.height
      ) {
        return (
          <IllustrationContainer key={project.id}>
            <img
              srcSet={`${project.primary.image.url}&w=900 1x,
                ${project.primary.image.url}&w=900&fit=max&dpr=2 2x,
                ${project.primary.image.url}&w=1200&fit=max&dpr=3 3x`}
              src={`${project.primary.image.url}&w=1200`}
              alt={project.primary.image.alt}
              loading="lazy"
              width="1000"
            />
          </IllustrationContainer>
        );
      } else {
        return (
          <IllustrationContainer key={project.id}>
            <img
              srcSet={`${project.primary.image.url}&w=800 1x,
                ${project.primary.image.url}&w=800&fit=max&dpr=2 2x,
                ${project.primary.image.url}&w=800&fit=max&dpr=3 3x`}
              src={`${project.primary.image.url}&w=800`}
              alt={project.primary.image.alt}
              loading="lazy"
              className="gallery-image"
              width="1000"
            />
          </IllustrationContainer>
        );
      }
    } else if (project.slice_type === 'animation') {
      if (
        project.primary.orientation === 'Landscape' ||
        project.primary.orientation === null
      ) {
        return (
          <AnimationContainerLandscape key={project.id}>
            {project.primary.short_video !== null && (
              <video
                // data-src={project.primary.short_video.url}
                src={project.primary.short_video.url}
                loading="lazy"
                muted
                autoPlay
                loop
                playsInline
                width="1000"
                preload="auto"
                className="lazy"
              />
            )}
          </AnimationContainerLandscape>
        );
      } else if (project.primary.orientation === 'Portrait') {
        return (
          <AnimationContainerPortrait key={project.id}>
            {project.primary.short_video !== null && (
              <video
                // data-src={project.primary.short_video.url}
                src={project.primary.short_video.url}
                loading="lazy"
                muted
                autoPlay
                loop
                playsInline
                width="1000"
                preload="auto"
                className="lazy"
              />
            )}
          </AnimationContainerPortrait>
        );
      }
    }
  });

  // function checkIfVideo() {
  //   let currentSlide = document
  //     .querySelector('.slide.selected')
  //     .querySelector('.lazy');

  //   if (currentSlide !== null) {
  //     currentSlide.src = currentSlide.getAttribute('data-src');
  //   }
  // }

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      infiniteLoop={true}
      selectedItem={activeSlide}
      swipeable={true}
      useKeyboardArrows={true}
      // onChange={() => checkIfVideo()}
    >
      {allProjects}
    </Carousel>
  );
};

export default Gallery;
