import React from 'react';
import styled from 'styled-components';

const Project = styled.div`
  cursor: pointer;

  text-align: center;

  grid-column: ${(props) => props.gridColumn};
`;

const Title = styled.h4`
  text-align: center;
  margin-top: 20px;
`;

const Client = styled.p`
  margin: 0;

  font-size: 16px;
  line-height: 1.25;

  margin-bottom: 20px;
`;

const ImageWrapperLandscape = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 62.4%;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img,
  & video {
    object-fit: cover;
    object-position: center center;

    width: 100%;
    height: 100%;
  }
`;

const ImageWrapperPortrait = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 133.333%;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img,
  & video {
    object-fit: cover;
    object-position: center center;

    width: 100%;
    height: 100%;
  }
`;

const AnimationProject = ({ data, openSlideshow, activeFilter, index }) => {
  if (data.orientation === 'Landscape' || data.orientation === null) {
    return (
      <Project gridColumn={'span 2'} onClick={() => openSlideshow(index)}>
        <ImageWrapperLandscape>
          <div>
            {data.short_video !== null && (
              <video
                src={data.short_video.url}
                muted
                autoPlay
                loop
                playsInline
              />
            )}
          </div>
        </ImageWrapperLandscape>

        {activeFilter === 'animation' && (
          <React.Fragment>
            <Title>{data.title}</Title>
            <Client>{data.client}</Client>
          </React.Fragment>
        )}
      </Project>
    );
  } else if (data.orientation === 'Portrait') {
    return (
      <Project gridColumn={'span 1'} onClick={() => openSlideshow(index)}>
        <ImageWrapperPortrait>
          <div>
            {data.short_video !== null && (
              <video
                src={data.short_video.url}
                muted
                autoPlay
                loop
                playsInline
              />
            )}
          </div>
        </ImageWrapperPortrait>

        {activeFilter === 'animation' && (
          <React.Fragment>
            <Title>{data.title}</Title>
            <Client>{data.client}</Client>
          </React.Fragment>
        )}
      </Project>
    );
  }
};

export default AnimationProject;
