import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Modal from 'react-modal';
import Helmet from 'react-helmet';
import { RemoveScroll } from 'react-remove-scroll';

import { ArtistFilters } from './artist-filters';
import ArtistBio from './artistBio';
import Projects from './projects';
import Gallery from './gallery';
import AnimationProjects from './animation-projects';
import MobileMenuIcon from '../Icons/mobile-menu-icon';
import { ArtistShowreel } from './artist-showreel';

Modal.setAppElement('#___gatsby');

const ArtistSubNav = styled.div`
  position: sticky;
  top: 79px;

  width: 100%;
  padding: 0;
  z-index: 100;
  background-color: #f6f8fe;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;

  font-size: 18px;

  @media (max-width: 767px) {
    padding: 0;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    top: 72px;
  }
`;

const DropdownMenu = styled.div`
  width: calc(100% - 99px);
  z-index: 100;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;

  background-color: #f6f8fe;

  position: fixed;
  top: 79px;

  font-size: 18px;

  transform: translateY(${(props) => (props.position ? '0' : '-100%')});
  visibility: ${(props) => (props.position ? 'visible' : 'hidden')};

  @media (max-width: 767px) {
    width: calc(100% - 49px);
  }

  @media (max-width: 576px) {
    width: calc(100% - 40px);
  }

  @media (max-width: 500px) {
    top: 72px;
    font-size: 16px;
  }
`;

const ArtistBioButton = styled.button`
  cursor: pointer;
  order: 2;

  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  border: 0;
  background-color: transparent;
  color: #013be8;

  &:hover {
    color: #fa3c44;
  }

  &:focus {
    outline: 0;
  }
`;

const artistBioStyle = {
  height: '100%',
  minHeight: '100vh',
  overflow: 'auto',
};

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    zIndex: 1000000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: '0',
    borderRadius: '0',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
};

const artistBioStyles = {
  overlay: {
    backgroundColor: 'transparent',
    zIndex: 900,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: '0',
    borderRadius: '0',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
};

const CloseModal = styled.button`
  position: absolute;
  top: 25px;
  right: 21px;
  z-index: 30000;
  cursor: pointer;
  font-size: 35px;
  line-height: 0;

  color: #fa3c44;
  font-weight: bold;

  width: 21px;
  height: 21px;
`;

const ProjectsContainer = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: dense;
  grid-gap: 30px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`;

const SingleArtist = ({ data, location, activeFilter, setActiveFilter }) => {
  const [artistBioOpen, setArtistBioOpen] = useState(false);
  const [allFilters, setAllFilters] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);

  const [projects, setProjects] = useState([]);
  const [animationProjects, setAnimationProjects] = useState([]);
  const [shortVideoProjects, setShortVideoProjects] = useState([]);
  const [typographyProjects, setTypographyProjects] = useState([]);
  const [editorialProjects, setEditorialProjects] = useState([]);
  const [zineProjects, setZineProjects] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [stickyNavbar, setStickyNavbar] = useState(false);

  const targetRef = useRef();

  useEffect(() => {
    let header = document.querySelector('header');
    header.style.paddingBottom = '26px';

    const allTags = data.prismicArtist.data.body
      .map((tag) =>
        tag.items
          .filter((tag) => tag.project_tags.document !== null)
          .map((tag) => tag.project_tags.document.data.title.text)
      )
      .flat();

    const allTagsArray = Array.from(new Set(allTags));

    const illustrationProjects = data.prismicArtist.data.body.filter(
      (project) => {
        let allTags = project.items
          .filter((tag) => tag.project_tags.document !== null)
          .map((tag) => tag.project_tags.document.data.title.text)
          .flat();
        return allTags.includes('Illustration');
      }
    );

    const shortAnimationProjects = data.prismicArtist.data.body.filter(
      (project) => project.slice_type === 'animation'
    );

    const animationProjects = data.prismicArtist.data.animations.filter(
      (project) =>
        project.animation !== null && project.animation.document !== null
    );

    if (animationProjects.length >= 1) {
      if (!allTagsArray.includes('Animation')) {
        allTagsArray.push('Animation');
      }
    }

    // Editorial
    if (data.prismicArtist.data.body1.length >= 1) {
      if (!allTagsArray.includes('Editorial')) {
        allTagsArray.push('Editorial');
      }
    }

    // Zines
    if (data.prismicArtist.data.body2.length >= 1) {
      if (!allTagsArray.includes('Zines')) {
        allTagsArray.push('Zines');
      }
    }

    // Typography
    if (data.prismicArtist.data.body3.length >= 1) {
      if (!allTagsArray.includes('Typography')) {
        allTagsArray.push('Typography');
      }
    }

    let incomingFilterState;
    if (location.state !== null && location.state.activeFilter !== undefined) {
      incomingFilterState = location.state.activeFilter;
    }
    // else {
    //   incomingFilterState = 'Illustration';
    // }

    setAnimationProjects(animationProjects);
    setShortVideoProjects(shortAnimationProjects);
    setProjects(illustrationProjects);
    setEditorialProjects(data.prismicArtist.data.body1);
    setZineProjects(data.prismicArtist.data.body2);
    setTypographyProjects(data.prismicArtist.data.body3);

    setAllFilters(allTagsArray);
    // setActiveFilter(incomingFilterState);
  }, []);

  const changeFilter = (filter) => {
    window.scrollTo(0, 0);

    setActiveFilter(filter);

    if (activeFilter !== 'Animation') {
      let filteredProjects = data.prismicArtist.data.body.filter((project) => {
        let allTags = project.items
          .filter((tag) => tag.project_tags.document !== null)
          .map((tag) => tag.project_tags.document.data.title.text)
          .flat();
        return allTags.includes(activeFilter);
      });

      if (filteredProjects.length === 0) {
        setProjects(data.prismicArtist.data.body);
      } else {
        setProjects(filteredProjects);
      }
    }
  };

  const openArtistBio = () => {
    setArtistBioOpen(!artistBioOpen);

    let body = document.querySelector('body');
    let header = document.querySelector('header');

    if (artistBioOpen === true) {
      body.style.backgroundColor = 'rgb(181, 197, 246)';
      header.style.backgroundColor = 'transparent';
      header.style.transition = 'none';

      const timer = setTimeout(() => {
        header.style.backgroundColor = 'rgb(181, 197, 246)';
      }, 501);
    } else {
      body.style.backgroundColor = 'rgb(246, 248, 254)';
      header.style.backgroundColor = '';
      header.style.transition = 'none';
      header.style.transitionDelay = '';
    }
  };

  const closeArtistBio = () => {
    setArtistBioOpen(false);

    let body = document.querySelector('body');
    let header = document.querySelector('header');

    body.style.backgroundColor = 'rgb(246, 248, 254)';
    header.style.backgroundColor = '';
    header.style.transition = '0ms background-color ease';
    header.style.transitionDelay = '';
  };

  const afterOpenModal = () => {
    let artistBioContainer = document.querySelector('.close-artist-bio');
    let body = document.querySelector('body');
    let header = document.querySelector('header');

    if (artistBioOpen === true) {
      artistBioContainer.style.backgroundColor = 'rgb(181, 197, 246)';
      body.style.backgroundColor = 'rgb(181, 197, 246)';
      header.style.backgroundColor = 'transparent';
      header.style.transition = 'none';

      const timer = setTimeout(() => {
        header.style.backgroundColor = 'rgb(181, 197, 246)';
      }, 501);
    } else {
      artistBioContainer.style.backgroundColor = 'rgb(246, 248, 254)';
      body.style.backgroundColor = 'rgb(246, 248, 254)';
      header.style.backgroundColor = '';
      header.style.transition = 'none';
      header.style.transitionDelay = '';
    }
  };

  const openSlideshow = (index) => {
    setModalIsOpen(true);
    setActiveSlide(index);
  };

  const closeSlideshow = () => {
    setModalIsOpen(false);
    setActiveSlide(0);
  };

  const seoImage = data.prismicArtist.data.body
    .filter((slice) => slice.slice_type === 'image')
    .filter((slice, index) => index === 0);

  return (
    <>
      <DropdownMenu>
        {allFilters && (
          <ArtistFilters
            onClick={changeFilter}
            activeFilter={activeFilter}
            allFilters={allFilters}
            artistUid={data.prismicArtist.uid}
          />
        )}
        <ArtistBioButton onClick={() => openArtistBio()}>
          {data.prismicArtist.data.name.text} Bio
        </ArtistBioButton>
      </DropdownMenu>

      <Helmet
        title={data.prismicArtist.data.name.text + ' – Everyone Agency'}
        meta={[
          {
            name: 'description',
            content: `${data.prismicArtist.data.short_text}`,
          },
          {
            name: 'og:image',
            content: `${
              seoImage.length >= 1 ? seoImage[0].primary.image.url : ``
            }`,
          },
          {
            name: 'og:image:secure_url',
            content: `${
              seoImage.length >= 1 ? seoImage[0].primary.image.url : ``
            }`,
          },
          {
            name: 'og:description',
            content: `${data.prismicArtist.data.short_text}`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `${data.prismicArtist.data.name.text}`,
          },
          {
            name: 'twitter:description',
            content: `${data.prismicArtist.data.short_text}`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${
              seoImage.length >= 1 ? seoImage[0].primary.image.url : ``
            }`,
          },
        ]}
      />

      {artistBioOpen === true && (
        <RemoveScroll>
          <Modal
            isOpen={artistBioOpen}
            onRequestClose={() => openArtistBio()}
            onAfterOpen={() => afterOpenModal()}
            style={artistBioStyles}
            contentLabel="Artist bio"
          >
            <Col
              col={12}
              onClick={() => closeArtistBio()}
              style={artistBioStyle}
              className="close-artist-bio"
            >
              <Row
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
                className="artist-bio-container"
              >
                <Col col={12} md={11} lg={10}>
                  <ArtistBio
                    image={data.prismicArtist.data.image}
                    text={data.prismicArtist.data.text}
                    padding={'100px 0'}
                    onClick={() => closeArtistBio()}
                  />
                </Col>
              </Row>
            </Col>
          </Modal>
        </RemoveScroll>
      )}

      <>
        <ArtistShowreel
          name={data.prismicArtist.data.name}
          showreel={data.prismicArtist.data.showreel}
        />
        <ArtistSubNav id="artist-navigation">
          {allFilters && (
            <ArtistFilters
              onClick={changeFilter}
              activeFilter={activeFilter}
              allFilters={allFilters}
              artistUid={data.prismicArtist.uid}
            />
          )}
          <ArtistBioButton onClick={() => openArtistBio()}>
            {data.prismicArtist.data.name.text} Bio
          </ArtistBioButton>
        </ArtistSubNav>

        <ProjectsContainer>
          {activeFilter === 'Animation' && animationProjects.length >= 1 ? (
            <>
              <AnimationProjects projects={animationProjects} />
            </>
          ) : (
            <>
              {activeFilter === 'Illustration' && (
                <Projects
                  projects={projects}
                  openSlideshow={openSlideshow}
                  activeFilter={activeFilter}
                />
              )}
              {activeFilter === 'Editorial' && (
                <Projects
                  projects={editorialProjects}
                  openSlideshow={openSlideshow}
                  activeFilter={activeFilter}
                />
              )}
              {activeFilter === 'Zines' && (
                <Projects
                  projects={zineProjects}
                  openSlideshow={openSlideshow}
                  activeFilter={activeFilter}
                />
              )}
              {activeFilter === 'Typography' && (
                <Projects
                  projects={typographyProjects}
                  openSlideshow={openSlideshow}
                  activeFilter={activeFilter}
                />
              )}
            </>
          )}
        </ProjectsContainer>

        {modalIsOpen && (
          <RemoveScroll>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => closeSlideshow()}
              style={customStyles}
              contentLabel="Image gallery"
              ref={targetRef}
            >
              <Gallery projects={projects} activeSlide={activeSlide} />
              <CloseModal onClick={() => closeSlideshow()}>
                <MobileMenuIcon isOpen={true} width={'20px'} height={'20px'} />
              </CloseModal>
            </Modal>
          </RemoveScroll>
        )}
      </>
    </>
  );
};

export default SingleArtist;
