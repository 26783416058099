import React from 'react';
import styled from 'styled-components';

const Project = styled.div`
  cursor: pointer;

  height: 100%;
  grid-column: ${(props) => props.gridColumn};

  & img {
    object-fit: cover;
    object-position: center center;

    width: 100%;
    height: 100%;
  }

  background-color: rgba(69, 109, 232, 0.4);
`;

const IllustrationProject = ({ data, openSlideshow, index }) => {
  if (data.image !== null) {
    if (data.image.dimensions.width > data.image.dimensions.height) {
      return (
        <Project gridColumn={'span 2'} onClick={() => openSlideshow(index)}>
          <img
            srcSet={`${data.image.url}&w=800 1x,
                ${data.image.url}&w=800&fit=max&dpr=2 2x,
                ${data.image.url}&w=800&fit=max&dpr=3 3x`}
            src={`${data.image.url}&w=800`}
            alt={data.image.alt}
            loading="lazy"
            width="800"
          />
        </Project>
      );
    } else {
      return (
        <Project gridColumn={'span 1'} onClick={() => openSlideshow(index)}>
          <img
            srcSet={`${data.image.url}&w=600 1x,
                ${data.image.url}&w=600&fit=max&dpr=2 2x,
                ${data.image.url}&w=600&fit=max&dpr=3 3x`}
            src={`${data.image.url}&w=600`}
            alt={data.image.alt}
            loading="lazy"
            width="600"
          />
        </Project>
      );
    }
  } else {
    return null;
  }
};

export default IllustrationProject;
