import React from 'react';
import styled from 'styled-components';
import MobileMenuIcon from '../Icons/mobile-menu-icon';
import { Col, Row } from 'styled-bootstrap-grid';

const MobileCloseModal = styled.button`
  cursor: pointer;
  display: none;

  @media (max-width: 767px) {
    z-index: 200;
    position: absolute;

    display: block;
    top: 90px;
    left: 10px;
    right: 0;
    text-align: left;

    width: 20px;
    height: 20px;
  }
`;

const ArtistBioContainer = styled.div`
  position: relative;
  padding: 100px 0;

  z-index: 100;

  & .image-container {
    margin: 0 0 1em 0;

    & img {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  & .text-container {
    & p {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 100px 10px;
  }
`;

const ArtistBio = ({ text, padding, onClick, image }) => {
  return (
    <ArtistBioContainer>
      <MobileCloseModal onClick={() => onClick()}>
        <MobileMenuIcon isOpen={true} width={'20px'} height={'20px'} />
      </MobileCloseModal>

      <Row>
        <Col col={12} md={12} lg={6}>
          <div className="image-container">
            {image.fluid !== null && (
              <img
                srcSet={image.fluid.srcSetWebp}
                src={image.fluid.srcWebp}
                alt={image.alt}
                loading={`lazy`}
              />
            )}
          </div>
        </Col>

        <Col col={12} md={12} lg={6}>
          <div className="text-container">
            <div
              padding={padding}
              dangerouslySetInnerHTML={{
                __html: text.html,
              }}
            />
          </div>
        </Col>
      </Row>
    </ArtistBioContainer>
  );
};

export default ArtistBio;
