import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: ${(props) => props.aspectRatio + `%`};
  margin-bottom: 30px;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Playbutton = styled.div`
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 44px;

  border-color: transparent transparent transparent #fff;
  transition: 100ms opacity ease;
  cursor: pointer;

  // play state
  border-style: solid;
  border-width: 22px 0 22px 40px;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 50;

  &:hover {
    opacity: 0.8;
  }
`;

const Poster = styled.div`
  opacity: ${(props) => (props.visible === true ? 1 : 0)};

  transition: 1000ms opacity ease;

  & img {
    z-index: 40;

    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

async function getVimeoThumbnail(video, width, height) {
  let response = await fetch(
    `https://vimeo.com/api/oembed.json?url=${video}&width=${width}&height=${height}&quality=1080`
  );

  let data = await response.json();
  return data;
}

let vimeoThumbnail;
class Iframe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnail: null,
    };
  }
  componentDidMount() {
    if (this.props.provider_name === 'Vimeo') {
      vimeoThumbnail = getVimeoThumbnail(this.props.video, 1920, 1080);
      vimeoThumbnail.then((result) => {
        this.setState({
          thumbnail: result.thumbnail_url,
        });
      });
    } else {
      vimeoThumbnail = this.props.thumbnail_url;
    }
  }

  render() {
    if (this.props.currentPlayingVideo === this.props.vimeoId) {
      return (
        <IframeContainer
          aspectRatio={(this.props.height / this.props.width) * 100}
        >
          <Poster visible={false}>
            <Playbutton
              onClick={() => this.props.handlePlay(this.props.vimeoId)}
            />
            {this.state.thumbnail !== null && (
              <img src={this.state.thumbnail} alt="Thumbnail" />
            )}
          </Poster>
          <ReactPlayer
            url={this.props.video}
            width="100%"
            height="100%"
            playing={true}
            controls
            loading="lazy"
          />
        </IframeContainer>
      );
    } else {
      return (
        <IframeContainer
          aspectRatio={(this.props.height / this.props.width) * 100}
        >
          <Poster visible={true}>
            <Playbutton
              onClick={() => this.props.handlePlay(this.props.vimeoId)}
            />
            <img src={this.state.thumbnail} alt="Thumbnail" />
          </Poster>
          <ReactPlayer
            url={this.props.video}
            width="100%"
            height="100%"
            playing={false}
            controls
            loading="lazy"
          />
        </IframeContainer>
      );
    }
  }
}

export default Iframe;
