import React from 'react';
import styled from 'styled-components';
import Iframe from './iframe';

const Project = styled.div`
  cursor: pointer;

  text-align: center;

  grid-column: ${(props) => props.gridColumn};
`;

const Title = styled.h4`
  text-align: center;
  margin-top: 20px;
`;

const Client = styled.p`
  margin: 0;

  font-size: 16px;
  line-height: 1.25;

  margin-bottom: 20px;
`;

class AnimationProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPlayingVideo: null,
    };
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
  }

  handlePlay = (vimeoId) => {
    this.setState({
      currentPlayingVideo: vimeoId,
    });
  };

  handlePause = () => {
    this.setState({
      currentPlayingVideo: null,
    });
  };

  render() {
    const allProjects = this.props.projects.map((project, index) => {
      return (
        <Project gridColumn={'span 2'} key={project.animation.uid}>
          <Iframe
            video={project.animation.document.data.video.embed_url}
            thumbnail={project.animation.document.data.video.thumbnail_url}
            width={project.animation.document.data.video.width}
            height={project.animation.document.data.video.height}
            vimeoId={project.animation.document.id}
            thumbnail_url={project.animation.document.data.video.thumbnail_url}
            provider_name={project.animation.document.data.video.provider_name}
            currentPlayingVideo={this.state.currentPlayingVideo}
            handlePlay={(vimeoId) => this.handlePlay(vimeoId)}
            handlePause={() => this.handlePause()}
          />
          <Title>{project.animation.document.data.video_title}</Title>
          <Client>{project.animation.document.data.video_client}</Client>
        </Project>
      );
    });

    return <React.Fragment>{allProjects}</React.Fragment>;
  }
}

export default AnimationProjects;
