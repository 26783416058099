import React from 'react';
import IllustrationProject from './illustrationProject';
import AnimationProject from './animationProject';

const Projects = ({ projects, openSlideshow, activeFilter }) => {
  const allProjects = projects.map((project, index) => {
    if (project.slice_type === 'illustration') {
      return (
        <IllustrationProject
          data={project.primary}
          openSlideshow={openSlideshow}
          index={index}
          key={project.id}
        />
      );
    } else if (project.slice_type === 'animation') {
      return (
        <AnimationProject
          data={project.primary}
          openSlideshow={openSlideshow}
          activeFilter={activeFilter}
          index={index}
          key={project.id}
        />
      );
    }
  });

  return allProjects;
};

export default Projects;
